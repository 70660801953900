import styled from 'styled-components';

import { useState } from 'react';

import Chip from './Chip';
import { TChipGroupProps } from './types';

export default function ChipGroup({ items = [], onSelect }: TChipGroupProps) {
  const [selectedValues, setSelectedValues] = useState<Array<string>>([]);

  function handleOnSelect(value: string) {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((selectedValue) => value !== selectedValue)
      : [...selectedValues, value];

    setSelectedValues(newSelectedValues);
    onSelect(newSelectedValues);
  }

  return (
    <Container>
      {items.map((item, i) => (
        <Chip
          key={i}
          label={item.label}
          value={item.value}
          isSelected={selectedValues.includes(item.value)}
          onSelect={handleOnSelect}
        ></Chip>
      ))}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;
