export const TOKENIZED_PAGES = [
  '/messages/[id]',
  '/my-team/invitation',
  '/referrals/claim',
  '/settings/referral-agreement-by-id',
  '/settings/referral-agreement-by-id/success',
  '/settings/referral-agreement-by-id/sign-complete',
];

export const EQUITY_APP_PAGES = ['/bbys/new/[partnerSlug]', '/bbys/new'];

export const PUBLIC_PAGES = [
  '/private-login',
  '/email-verified',
  '/survey/closing-services/csat/[webId]',
  '/warm-transfer-opt-in',
  '/lender/equity-unlock-calculator',
  '/lender/request-agreement',
  '/listings/[slug]',
  '/listings/[slug]/offers/[offerId]',
  '/listings/[slug]/offers/[offerId]/new',
  '/agents/[agentSlug]',
  '/404',
  '/express-approval',
  '/public/tasks/[templateSlug]',
  '/pay-per-lead/success',
  '/pay-per-lead/investor-success',
  '/investors/terms-of-use',
];

export const PAGES_TO_HIDE_HEADER = [
  /** Agent Portal Pages */
  '/active-partnership',
  '/agent-onboarding',
  '/email-verified',
  '/listings/[slug]',
  '/listings/[slug]/offers/[offerId]',
  '/listings/[slug]/offers/[offerId]/new',
  '/settings/referral-agreement',
  '/transactions/[orderId]/leads/[leadId]/offer',
  '/transactions/[orderId]/leads/[leadId]/offer/[offerId]',
  '/transactions/[orderId]/offers/edit',
  '/transactions/[orderId]/offers/page-setup',
  '/transactions/[orderId]/offers/[offerId]/details',
  '/transactions/[orderId]/offers/[offerId]/new',
  '/transactions/calculator',
  '/transactions/[orderId]/[userType]/tasks/[taskId]/property-photos',
  '/transactions/[orderId]/[userType]/tasks/[taskId]/property-photos/upload-success',
  '/transactions/[orderId]/[userType]/tasks/[taskId]/property-cma',
  '/closing-services-submission',
  '/cash-offer-submission',
  '/trade-in-submission',
  '/simple-sale-submission',
  '/closing-services/instant-open',
  '/closing-services/sellers-net-sheet-generator',
  '/settings/referral-agreement-by-id',
  '/settings/referral-agreement-by-id/success',
  '/settings/referral-agreement-by-id/sign-complete',
  '/buy-before-you-sell/equity-unlock-calculator',
  '/agents/[agentSlug]',
  '/express-approval',
  '/lender/equity-unlock-calculator',
  '/lender/request-agreement',
  '/public/tasks/[templateSlug]',
  '/pay-per-lead/success',
  '/pay-per-lead/investor-success',
  '/bbys/new/[partnerSlug]',
  '/bbys/new',
  '/investors/terms-of-use',
];

export const PAGES_TO_STYLE_HUBSPOT_CHAT = [
  '/trade-in-submission',
  '/cash-offer-submission',
  '/simple-sale-submission',
  '/closing-services-submission',
];

export const PAGES_WITH_SMOOTH_SCROLL = ['/agents/[agentSlug]'];

export const SELF_SERVICE_FLOW_PAGES = [
  '/agents/[agentSlug]',
  '/express-approval',
];
